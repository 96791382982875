<template>
  <div id="app" :class="{ 'pc-bg-color': checkPcBgColorClass() }">
    <router-view />
    <!-- <iframe v-if="!isMobile && !this.$route.path.includes('/hospital')" :src="hospitalUrl" id="ollacare-hospital" class="hidden-iframe" /> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import VueCookies from 'vue-cookies';
import postMessageController from '@/lib/OllacareHospital';

export default {
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
      hospitalInfo: 'hospitalInfo',
      userInfo: 'userInfo',
      init: 'init',
    }),
    postMessage() {
      return postMessageController();
    },
    hospitalUrl() {
      if (location?.origin.includes('localhost') || location?.origin.includes('dev.docple.com')) {
        return 'https://dev.hospital.ollacare.com';
      }
      if (location?.origin.includes('stage.docple.com')) {
        return 'https://stage.hospital.ollacare.com';
      }
      if (location?.origin.includes('docple.com')) {
        return 'https://hospital.ollacare.com';
      }

      return 'https://hospital.ollacare.com';
    },
  },
  created() {
    // eslint-disable-next-line no-unused-vars
    window.onerror = function(message, source, lineno, colno, error) {
      const isChunkVendorsError = source && source.includes('chunk-vendors');

      if (isChunkVendorsError) {
        console.error('chunk-vendors 파일에서 오류 발생:', message);

        setTimeout(() => {
          location.reload();
        }, 3000);
      }
      return true;
    };
    // iOS Header, Footer issue
    const doc = document.documentElement;
    doc.style.setProperty('--vh', `${window.innerHeight}px`);

    window.addEventListener('resize', () => {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', `${window.innerHeight}px`);
    });
    this.loadBlock(window.localStorage.getItem('block'));
    this.addTaboolaScript();
    this.addAdsenceScript();
  },
  async mounted() {
    this.postMessage.addPostMessageEventListener(this.handlePostMessageEvent);
    await this.$store.dispatch('getInit');
  },
  beforeDestroy() {
    this.postMessage.removePostMessageEventListener(this.handlePostMessageEvent);
  },
  methods: {
    ...mapMutations(['loadBlock']),
    checkPcBgColorClass() {
      if (this.$route.path === '/m-rx') {
        return false;
      }
      return !this.isMobile;
    },
    /**
     * @author Ash
     * @date 2024.03.11
     * @param {{eventName: string, data: any, actionTime: string}} event 수신 데이터
     * @description iframe과 통합 데이터 전송 수단, eventName으로 분기처리 필요
     */
    handlePostMessageEvent(event) {
      const { origin, data } = event;
      if (origin.includes('hospital.ollacare.com')) {
        this.hospitalPostMessage(data);
        return;
      }
      if (origin.includes('docple.com')) {
        this.insightPostMessage(data);
        return;
      }
    },
    hospitalPostMessage(data) {
      if (data?.eventName === 'hospitalInfo') {
        this.$store.dispatch('setHospitalInfo', data?.data);
        return;
      }
      if (data?.eventName === 'logout') {
        this.$store.dispatch('setHospitalInfo', null);
        VueCookies.set(
          'dailyHospitalAlert',
          'N',
          this.$moment(
            this.$moment()
              .add(1, 'days')
              .format('YYYY-MM-DD'),
          ).diff(this.$moment(), 'seconds'),
        );
      }
    },
    insightPostMessage(data) {
      /**
       * @author Ash
       * @description 인사이트 라우팅, 호출시 의사회원 체크
       */
      if (data?.eventName === 'insightRedirect' && data?.data?.redirectUrl) {
        this.gTagDataLayerPush(data?.data);
        this.clearDataLayer();

        if (this.userInfo.joinType !== '1') {
          alert(`의사회원만 이용할 수 있는 메뉴입니다.`);
          return;
        }

        location.href = data.data.redirectUrl;
      }
    },
    gTagDataLayerPush(data) {
      const isLogin = !!this.userInfo.uid;
      window.dataLayer.push({
        event: 'insight_iframe_click',
        event_category: 'insight',
        event_label: 'iframe',
        event_redirect_path: data?.redirectUrl,
        event_insight_code: data?.code,
        event_insight_name: data?.name,
        event_is_login: isLogin,
        value: 1,
      });
    },
    clearDataLayer() {
      window.dataLayer.push({
        event: null,
        event_category: null,
        event_label: null,
        event_redirect_path: null,
        event_insight_code: null,
        event_insight_name: null,
        event_is_login: null,
        value: null,
      });
    },
    addAdsenceScript() {
      if (this.isMobile) {
        return;
      }

      const scriptId = 'adsence-js-script';

      const existingScript = document.getElementById(scriptId);

      if (existingScript) {
        existingScript.remove();
      }

      const adsenceScript = document.createElement('script');
      adsenceScript.id = scriptId;
      adsenceScript.async = true;
      adsenceScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5526117903315334';
      adsenceScript.crossOrigin = 'anonymous';

      document.head.appendChild(adsenceScript);
    },
    addTaboolaScript() {
      const scriptId = 'taboola-flush-script';

      const existingScript = document.getElementById(scriptId);

      if (existingScript) {
        existingScript.remove();
      }

      const taboolaFlushScript = document.createElement('script');
      taboolaFlushScript.id = scriptId;
      taboolaFlushScript.type = 'text/javascript';
      taboolaFlushScript.innerHTML = `
        window._taboola = window._taboola || [];
        _taboola.push({flush: true});
    `;
      document.body.appendChild(taboolaFlushScript);
    },
  },
};
</script>
<style lang="scss" scoped>
.hidden-iframe {
  display: none;
  position: absolute;
  width: 0;
  height: 0;
}
</style>
